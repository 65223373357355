.cursor-custom {
    cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30"><circle cx="12" cy="12" r="8" fill="none" stroke="white" stroke-width="3"/></svg>'), auto;
  }
  
  .bubble {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
    opacity: 0.4;
    pointer-events: none;
    animation: fadeAway 1s linear forwards;
  }
  
  @keyframes fadeAway {
    from {
      transform: scale(1);
      opacity: 0.8;
    }
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
