@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Jura:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat');


@layer base {
    body {
        /* @apply font-[Bebas Neue]; */
        font-family: 'Bebas Neue', cursive;

    }
    li {
        @apply px-4;
        /* @apply cursor-pointer; */
        font-size: 1.4rem;
        @apply pl-2;
        @apply pr-2;
        /* font-family: 'Montserrat', sans-serif; */
    }
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.content-div:hover {
    background-image: linear-gradient(
        to right, 
        rgba(112, 157, 255, 0.8),
        hsla(242, 74%, 61%, 0.8)
    )!important;
}

.paragraph {
    font-family: 'Montserrat', sans-serif;

}

.paragraph-t {
    font-family: 'Montserrat', sans-serif;
    @apply pt-2;
    @apply pb-2;

}

.gallery {
    @apply hover:scale-105 transition duration-300 ease-in-out;
}